export const APPLICATION_LOAD = 'APPLICATION_LOAD';
export const LOAD_USER_LIST = 'LOAD_USER_LIST';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_USER = 'ADD_USER';
export const REMOVE_GUEST_USER = 'REMOVE_GUEST_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const BATCH_UPDATE_USERS = 'BATCH_UPDATE_USERS';
export const ROOMS_LOADED = 'ROOMS_LOADED';
export const START_MEETING = 'START_MEETING';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const UPDATE_FLOOR = 'UPDATE_FLOOR';
export const ROOMS_ADDED = 'ROOMS_ADDED';
export const SET_THEMES = 'SET_THEMES';
export const SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID';
export const SET_CURRENT_FLOOR_ID = 'SET_CURRENT_FLOOR_ID';
export const SET_BACKUP_FLOOR_ID = 'SET_BACKUP_FLOOR_ID';
export const SET_SIDE_PANEL_SEACH_TERM = 'SET_SIDE_PANEL_SEACH_TERM';
export const SET_FLOORPLAN_TRANSFORMS = 'SET_FLOORPLAN_TRANSFORMS';
export const UPDATE_OR_ADD_DEPARTMENT = 'UPDATE_OR_ADD_DEPARTMENT';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
export const REMOVE_DEPARTMENT = 'REMOVE_DEPARTMENT';
export const REMOVE_FLOOR = 'REMOVE_FLOOR';
export const SET_FLOORS = 'SET_FLOORS';
export const SET_UNFILTERED_FLOORS = 'SET_UNFILTERED_FLOORS';
export const SET_MAX_OCCUPANCY = 'SET_MAX_OCCUPANCY';
export const SET_PINNED_OFFICES = 'SET_PINNED_OFFICES';
export const SET_PINNED_PEOPLE = 'SET_PINNED_PEOPLE';
export const DISPLAY_ADMIN_TOOL = 'DISPLAY_ADMIN_TOOL';
export const SHOW_TOUR = 'SHOW_TOUR';
export const NO_POPUPS = 'NO_POPUPS';
// this is the flag that determines if the turtle shows that says: admins only
export const SET_ADMIN_ONLY_MODE = 'SET_ADMIN_TOOL';
export const SET_SELF_REGISTRATION = 'SET_SELF_REGISTRATION';
export const SET_CONFERENCES_ENABLED = 'SET_CONFERENCES_ENABLED';
export const SET_WORKSPACE_ID = 'SET_WORKSPACE_ID';
export const CONFERENCES_FETCHED = 'CONFERENCES_FETCHED';

export const PARTNER_PARTIES_FETCHED = 'PARTNER_PARTIES_FETCHED';
export const UPDATE_PARTNER_PARTY = 'UPDATE_PARTNER_PARTY';
export const DELETE_PARTNER_PARTY = 'DELETE_PARTNER_PARTY';
export const UPDATE_CONFERENCE = 'UPDATE_CONFERENCE';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const SESSION_DESTROY = 'SESSION_DESTROY';
export const SET_UNFILTERED_USERS = 'SET_UNFILTERED_USERS';
export const DELETE_CONFERENCE = 'DELETE_CONFERENCE';
export const DELETE_ROOMS = 'DELETE_ROOMS';
export const DELETE_ROOMS_BY_ID = 'DELETE_ROOMS_BY_ID';

export const UPDATE_BACKUP_FLOOR = 'UPDATE_BACKUP_FLOOR';
export const INCREMENT_NOTIFICATION_COUNT = 'INCREMENT_NOTIFICATION_COUNT';
export const RESET_NOTIFICATION_COUNT = 'RESET_NOTIFICATION_COUNT';
export const DECREMENT_NOTIFICATION_COUNT = 'DECREMENT_NOTIFICATION_COUNT';
export const SET_WAITING_ROOM_ENABLED = 'SET_WAITING_ROOM_ENABLED';
export const SET_ELEVATOR_LETTERS_ENABLED = 'SET_ELEVATOR_LETTERS_ENABLED';

export const ADD_NATIVE_NOTIFICATION = 'ADD_NATIVE_NOTIFICATION';