import _ from 'lodash';
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import {enterRoom, knock, setUserAvailable, setUserSocial, updateState} from './api.utils';
import {EVENT_STATE, PIN_ALLOWANCE_CUT_OFF, STATES} from '../constants/appConstants';
import {showErrorNotification} from './notifications';
import jQuery from 'jquery';

window['moment'] = moment;

export function getRoom(rooms, id) {
  if (!_.isEmpty(rooms) && id) {
    return _.find(rooms, { id: id });
  }
}

export function getOnlineUsers(userList, currentUserId, currentRoomId) {
  return _.chain(userList)
    .filter({ present: true })
    .difference(getUsersInRoom(userList, currentUserId, currentRoomId))
    .reject({ id: currentUserId })
    .orderBy(function (user) {
      return user.first_name.toLowerCase();
    }, 'asc')
    .value();
}

export function getUsersInRoom(userList, currentUserId, currentRoomId) {
  if (!_.isEmpty(userList)) {
    return _.chain(userList)
      .filter({ current_room_id: currentRoomId, present: true })
      .reject({ id: currentUserId })
      .orderBy(function (user) {
        return _.get(user, 'first_name').toLowerCase();
      }, 'asc').value();
  } else {
    return [];
  }
}

export function getOfflineUsers(userList) {
  return _.chain(userList)
    .filter({ present: false })
    .orderBy(function (user) {
      return user.first_name.toLowerCase();
    }, 'asc')
    .value();
}

export function formatTime(time, timezone) {
  if (!timezone) {
    timezone = moment.tz.guess();
  }
  return moment(time).tz(timezone).format('h:mm a');
}

export function formatDate(time, timezone) {
  if (!timezone) {
    timezone = moment.tz.guess();
  }
  return moment(time).tz(timezone).format('MMM, D YYYY');
}

export function formatLoginTime(time, timezone) {
  return moment(time).tz(timezone).format('MM-DD-YYYY, h:mm a');
}

export function clearMeeting() {
  jQuery('#meetingFrameWrapper').empty();
}

export function doStartMeeting(meetingUrl) {
  jQuery('#launchURL').attr('href', meetingUrl);
  setTimeout(() => {
    document.getElementById('launchURL').click();
    setTimeout(() => jQuery('#launchURL').attr('href', null), 100);
  }, 100);
}

export function doStartExternalMeeting(meetingUrl) {
  window.open(meetingUrl, '_blank');
}

export function startMeetingInPopup(meetingUrl, noPopupCallback) {
  const newWin = window.open(meetingUrl, 'Qube Zoom meeting', 'height=600,width=600,modal=yes,alwaysRaised=yes');
  if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
    noPopupCallback();
  }
}

export function formatDateTime(timestamp, timezone) {
  if (!timezone) {
    timezone = moment.tz.guess();
  }
  return moment(timestamp).tz(timezone).format('MMMM Do, YYYY h:mm a');
}

export function getInitialZoomLevel(expectedWidth, expectedHeight) {
  const xBuff = 100;
  const yBuff = 50;
  const availableHeight = window.innerHeight - 70 - yBuff;
  const availableWidth = window.innerWidth - 250 - xBuff;

  const scale = _.min([availableWidth / expectedWidth, availableHeight / expectedHeight]);

  // determine translation based on initial scale:
  const width = expectedWidth * scale;
  const height = expectedHeight * scale;

  const y = (availableHeight - height + yBuff) / 2;
  const x = (availableWidth - width + xBuff) / 2;
  return { scale, x, y };
}

export function tooltipMe(tooltip, id, toolTipped, placement = 'top') {
  if (_.isArray(tooltip)) {
    return <OverlayTrigger
      overlay={<Tooltip id={`id`}>{_.map(tooltip, (tip, idx) => {
        if (_.isNil(tip) || tip === '') {
          return '';
        }
        return <span key={`ttSpan_${id}_${idx}`}>{tip}<br /></span>;
      })}</Tooltip>}
      placement={placement}>
      {toolTipped}
    </OverlayTrigger>;
  } else {
    return <OverlayTrigger
      overlay={<Tooltip id={`id`}>{tooltip}</Tooltip>}
      placement={placement}>
      {toolTipped}
    </OverlayTrigger>;
  }
}

export function knockOrGo(roomId, currentRoomId, homeId, roomList) {
  if (roomId === currentRoomId) {
    return;
  }

  const room = _.find(roomList, { id: roomId });
  if (_.get(room, 'room_type', 'office') !== 'office' || homeId === roomId) {
    enterRoom(roomId);
  } else {
    knock(roomId);
  }
}

export function getUserName(user, currentUserIsConferenceGuest, selfRegistration) {
  const firstName = _.get(user, 'first_name', '');
  const lastName = _.get(user, 'last_name', '');
  if (selfRegistration || currentUserIsConferenceGuest) {
    return (!!firstName ? firstName : 'No name?!');
  } else {
    return (!!firstName ? firstName : '') + ' ' + (!!lastName ? lastName : '');
  }
}

export function prepareFloors(data) {
  return _.chain(data.data)
    .map(floor => _.assign(floor, { id: _.toNumber(floor.id) }, { ...floor.attributes }))
    .sortBy('level')
    .compact()
    .map((floor, idx) => _.assign(floor, { level: idx + 1 }, { conference: floor.level >= 100 }))
    .value();
}

export function limitFloors(floors, conferences, rooms, currentUser) {
  let conferenceFloors;
  if(currentUser.type === 'Partner') {
    return _.filter(floors, 'partnerFloor');
  }
  if (currentUser.isConferenceUser) {
    // show only conferences, unless office_preview is enabled on at least one, then show the office as well
    if (_.some(conferences, 'office_preview')) {
      conferenceFloors = floors;
    } else {
      // return only conference floors
      conferenceFloors = _.chain(floors)
        .filter({ conference: true })
        .map((floor, idx) => _.assign(floor, { level: idx + 1 }))
        .sortBy('level')
        .value();
    }
  } else {
    // if the current user is an admin they should see ALL floors
    // registered users can see floors that:
    // all office floors
    // are visible_to_office
    // or
    // floors for events that they are hosts for
    // or
    // floors that they are currently on --> so every time a user moves to a new room we need to update the floors
    // available (if they've changed)
    let filteredConferences;

    if (currentUser.admin) {
      filteredConferences = _.chain(conferences)
        .filter({ enabled: true })
        .compact()
        .value();

    } else {
      filteredConferences = _.chain(conferences)
        .filter({ enabled: true })
        .map((conf) => _.includes(conf.organizer_ids, currentUser.id) || conf.visible_to_office ? conf : null)
        .compact()
        .value();
    }

    const currentRoom = _.find(rooms, { id: currentUser.current_room_id });
    if (currentRoom) {
      if (!_.find(filteredConferences, { floor_id: currentRoom.floor_id })) {
        // add that conference as it should be visible to that user:
        const conference = _.find(conferences, { floor_id: currentRoom.floor_id });
        if (conference) {
          filteredConferences.push(conference);
        }
      }
    }
    conferenceFloors = _.chain(floors)
      .map(
        (floor) => (floor.partnerFloor || !floor.conference || !_.isUndefined(_.find(filteredConferences, { floor_id: floor.id }))) ? floor :
          null)
      .compact()
      .map(
        (floor, idx) => _.assign({}, floor, { level: idx + 1 })) // levels must be consecutive for the elevator to work
      .sortBy('level')
      .value();
  }

  return _.map(conferenceFloors, floor => {
    const event = _.find(conferences, { floor_id: floor.id });

    if (event) {
      return _.assign({}, floor, { status: event.status });
    }
    return floor;
  });
}

export function limitUserList(userList, currentUser, roomList, floors, conferences) {
  // conference users should only see users that are on their floors as well as hosts
  const floorIds = _.map(floors, 'id');
  if (currentUser.isConferenceUser) {
    const hostIds = _.flatMap(conferences, conf => conf.organizer_ids);
    return _.chain(userList).map((user) => {
      const currentRoom = _.find(roomList, { id: user.current_room_id });

      if ((currentRoom && _.includes(floorIds, currentRoom.floor_id) || _.includes(hostIds,
        user.id)) && !user.isGuest) {
        return user;
      }
    }).compact()
      .value();
  } else {
    // only conference users that are on floors that user has access too - show all other users
    return _.chain(userList)
      .map((user) => {
        if (user.isConferenceUser) {
          const currentRoom = _.find(roomList, { id: user.current_room_id });
          if (currentRoom && _.includes(floorIds, currentRoom.floor_id)) {
            return user;
          }
        } else {
          return user;
        }
      }).compact()
      .value();
  }
}
export function preparePartnerParties(parties) {
  return _.map(parties.data, (entry) => {
    const party = _.assign({ id: _.toNumber(entry.id) }, { ...entry.attributes });
    return party;
  });
}
export function prepareConferences(conferences) {
  return _.map(conferences.data, (entry) => {
    const conference = _.assign({ id: _.toNumber(entry.id) }, { ...entry.attributes });

    if (!_.isEmpty(_.get(entry.relationships, 'sessions.data'))) {
      const sessions = _.map(entry.relationships.sessions.data, (obj) => {
        const session = { ..._.find(conferences.included, { id: obj.id }).attributes };
        return session;
      });
      if (!_.isEmpty(sessions)) {
        _.assign(conference, { sessions });
      }
    }
    if (conference.layout === 'support') {
      _.assign(conference, { support: true });
    }
    return conference;
  });
}

//
export function prepareUsers(response, guestResponse, currentUserId) {
  const users = _.map(response.data, (entry) => {
    // assign the departments
    let department_ids = [];
    if (!_.isEmpty(_.get(entry, 'relationships.departments.data'))) {
      department_ids = _.map(entry.relationships.departments.data, (entry) => _.toNumber(entry.id));
    }
    return _.assign({ id: _.toNumber(entry.id) }, { ...entry.attributes }, {
      isConferenceUser: entry.attributes.type === 'ConferenceGuest',
      is_guest: false,
      department_ids,
    })
  });
  const guests = guestResponse ?
    _.map(guestResponse.data, entry => _.assign({ id: entry.id + '_guest' }, { ...entry.attributes }, {
      is_guest: true,
      isConferenceUser: false,
    })) : [];
  const user = _.find(users, { id: currentUserId });

  // map pinned and watching onto the user in the list
  // pinned rooms
  let pinnedRooms = [];
  const rawUser = _.find(response.data, { id: '' + currentUserId });
  if (!_.isEmpty(_.get(rawUser, 'relationships.pinned_rooms.data'))) {
    pinnedRooms = _.chain(rawUser.relationships.pinned_rooms.data).map((pinnedEntry) => {
      const pin = _.find(response.included, { id: pinnedEntry.id, type: 'pinned_room' });
      return pin ? pin.attributes : null;
    }).compact()
      .value();
  }

  // assign the slack dms
  if (user && !_.isEmpty(user.slack_dms)) {
    const slackDMS = JSON.parse(user.slack_dms);
    _.forEach(_.keys(slackDMS), (key) => {
      _.assign(_.find(users, { id: _.toNumber(key) }), { slackDMToken: slackDMS[key] });
    });
  }
  let watching = [];
  if (!_.isEmpty(_.get(rawUser, 'relationships.watching'))) {
    watching = _.chain(rawUser.relationships.watching.data)
      .map('id')
      .map((entry) => _.toNumber(entry))
      .value();
  }

  // add the "pinned" property to the user
  let pinnedPeopleIds = [];
  let pinnedRoomIds = [];
  let pinnedDepartments = [];
  if (!_.isEmpty(_.get(rawUser, 'relationships.follows.data'))) {
    _.chain(rawUser.relationships.follows.data).forEach((pinnedEntry) => {
      const pin = _.find(response.included, { id: pinnedEntry.id, type: 'follow' });
      if (pin) {
        if (pin.attributes.followable_type === 'Room') {
          pinnedRoomIds.push(pin.attributes.followable_id);
        } else if (pin.attributes.followable_type === 'Department') {
          pinnedDepartments.push(pin.attributes.followable_id);
        } else {
          pinnedPeopleIds.push(pin.attributes.followable_id)
        }
      }
    }).compact()
      .value();
  }
  const properUser = _.assign({}, { ...user }, {
    pinned_rooms: pinnedRooms,
    id: _.toNumber(user.id),
    watching_ids: watching,
    present: true, // this is important!!
    pinnedPeople: pinnedPeopleIds,
    pinnedRooms: pinnedRoomIds,
    pinnedDepartments,
  });

  _.remove(users, { id: _.toNumber(user.id) });
  users.push(properUser);

  return _.chain(users).concat(guests).flatten().value();
}

export function prepareRooms(roomList) {
  const list =  _.map(roomList, (entry) => _.assign({ id: _.toNumber(entry.id) }, { ...entry.attributes }));
  return list;
}

export function displayLightOut(user, userList, conferenceList, floorId) {
  const conference = _.find(conferenceList, { floor_id: floorId });
  if (_.get(conference, 'layout') === 'support') {
    // check if at least one host is online:
    const organizerIds = conference.organizer_ids;
    const availableUsers = _.chain(userList)
      .filter((u) => {
        if (u.present && (u.state === STATES.AVAILABLE || u.state === STATES.SOCIAL)) {
          return u;
        } else {
          return null;
        }
      })
      .compact()
      .value();
    return (_.size(_.intersection(organizerIds, _.map(availableUsers, 'id'))) < 1)
  } else {
    return false;
  }
}

// determine if a conference is current or past or upcoming
export function eventStatus(event, timezone) {
  if (!event) {
    return null;
  }
  const allSessions = event.sessions;
  const past = [], upcoming = [], present = [];
  const now = moment.tz(new Date(), timezone);

  _.forEach(allSessions, session => {
    if (_.isUndefined(session)) {
      return;
    }
    const sessionStartTime = moment(session.start_time).tz(timezone);
    if (now.isBefore(sessionStartTime)) {
      upcoming.push(session);
    } else if (now.isAfter(sessionStartTime)) {
      const sessionEndTime = moment(sessionStartTime).add(session.duration, 'minutes');
      if (sessionEndTime.isAfter(now)) {
        present.push(session);
      } else {
        past.push(session);
      }
    }
  });

  let status = '';
  if (_.isEmpty(present)) {
    if (event.layout === 'support') {
      status = EVENT_STATE.SUPPORT;
    } else if (_.isEmpty(upcoming) && !_.isEmpty(past)) {
      status = EVENT_STATE.PAST;
    } else if (!_.isEmpty(upcoming)) {
      status = EVENT_STATE.UPCOMING;
    }
  } else {
    status = EVENT_STATE.NOW;
  }

  if (status === EVENT_STATE.UPCOMING) {
    // find the first upcoming one and set a timer:
    const firstSession = _.head(_.sortBy(upcoming, 'start_time'));
    const millisToStart = moment(firstSession.start_time).tz(timezone).diff(moment.tz(new Date(), timezone));
    return _.assign(event, { status, millisToStart });
  }
  return _.assign(event, { status });
}

export function doTrack(event, params) {
  //mixpanel.track(event, params);
}

export function initLogger(doLog) {
  if (doLog) {
    window.debug = {
      log: window.console.log.bind(window.console, '%s: %s'),
      error: window.console.error.bind(window.console, 'error: %s'),
      info: window.console.info.bind(window.console, 'info: %s'),
      warn: window.console.warn.bind(window.console, 'warn: %s'),
    };
  } else {
    var __no_op = function () {
    };

    window.debug = {
      log: __no_op,
      error: __no_op,
      warn: __no_op,
      info: __no_op,
    }
  }
}

// this is the list of all top level departments ...
export function getTopLevelDepartments(data) {
  return _.filter(data, { parent_id: null });
}

// gets the list of top level departments and all their leaves -
// this can be useful for searching
export function getTopLevelDepartmentsAndAllChildren(data) {
  const topLevelOnly = getTopLevelDepartments(data);
  return _.map(topLevelOnly, (topLevel) => {
    const childIds = findAllChildren(topLevel, data, []);
    return ({ ...topLevel, allChildren: _.flatten(childIds) });
  });
}

function findAllChildren(parent, everything, childIds) {
  const children = _.filter(everything, { parent_id: parent.id });
  if (!_.isEmpty(children)) {
    const newChildIds = _.chain(childIds)
      .concat(_.flatten(children))
      .flatten()
      .uniq()
      .value();
    return _.chain(children)
      .flatMap((child) => findAllChildren(child, everything, newChildIds))
      .uniq()
      .value();
  } else {
    return _.chain(childIds).flatten().uniq().value();
  }
}

export function updateUserState(userId, state) {
  if (state === STATES.AVAILABLE) {
    setUserAvailable(userId);
  } else if (state === STATES.SOCIAL) {
    setUserSocial(userId);
  } else {
    updateState(state, userId);
  }
}

export function isPinningAllowed(userList) {
  return _.size(userList) > PIN_ALLOWANCE_CUT_OFF;
}

export function showBBTime(state) {
  return _.indexOf([STATES.AWAY, STATES.RIGHT_BACK, STATES.FOCUS_MODE, STATES.BUSY],
    state) > -1;
}

export function validateBBTime(time, timezone, state) {
  const now = moment().tz(timezone);
  let minutes, hours, isAM;
  time = _.toLower(time);
  time = _.trim(time);

  let regex = /\b((1[0-2]|0?[1-9]):([0-5][0-9])\s?([AaPp][Mm]))/;
  // 12:00AM, 3:00 PM case
  if (regex.test(time)) {
    return moment(time, ['hh:mm a']).utc().format();
  }

  // military time:
  regex = /^([0-1]?[0-9]|2[0-3])(:?[0-5][0-9])?$/;
  if (regex.test(time)) {
    time = _.replace(time, ':', '');
    hours = time.substring(0, 2);
    minutes = time.substring(2);
    now.set('hour', hours);
    now.set('minute', minutes);
    return moment.utc(now).format();
  }

  // something like 5pm
  regex = /^([1-9]|1[012])\s?([AaPp][Mm])/;
  if (regex.test(time)) {
    isAM = time.indexOf('am') > -1;
    hours = parseInt(_.replace(time, isAM ? 'am' : 'pm', ''));
    if (!isAM) {
      hours += 12;
    }

    now.set('hour', hours);
    now.set('minute', 0);
    return moment.utc(now).format();
  }

  if (state !== STATES.BUSY) {
    return 'INVALID';
  }

  return null;

}

export function getFloorDisplayName (floor, elevatorLettersEnabled) {
  if(floor.partnerFloor) {
    return 'P' + floor.level;
  } else if(floor.conference) {
    return 'C' + floor.level;
  } else if (elevatorLettersEnabled) {
    const words = _.split(floor.name, ' ');
    if (_.size(words) > 1) {
      let display = _.toLower(words[0]);
      if (display === 'a' || display === 'an' || display === 'the') {
        display = words[1];
      }
      return _.toUpper(display.substring(0, 1));
    } else {
      return _.toUpper(floor.name.substring(0, 1));
    }
  } else {
    return floor.level;
  }
}

export function getSubDomain() {
  const host = window.location.host;
  return host.substring(0, host.indexOf('.'));
}