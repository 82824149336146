import {NotificationManager} from 'react-notifications';

export function showSuccessNotification(message) {
  NotificationManager.success(message);
}

export function showErrorNotification(message, timeout= 10 * 1000) {

  NotificationManager.error(message, null , timeout);
}

export function showWarningNotification(message) {
  NotificationManager.warning(message);
}

export function showInfoNotification(message) {
  NotificationManager.warning(message);
}