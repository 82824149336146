import axios from 'axios';
import {STATES} from '../constants/appConstants';
import _ from 'lodash';
import {showSuccessNotification} from './notifications';

// used by conference modal
export function loadRooms() {
  return axios.get('/rooms.json').then((response) => _.map(response.data.data,
    (entry) => _.assign({ id: _.toNumber(entry.id) }, { ...entry.attributes })));
}

export function loadUsers() {
  return axios.get('/users.json');
}

export function slackUser(userId) {
  axios.get('/users/' + userId + '/message');
}

export function inviteUser(userId) {
  return axios.post('/users/' + userId + '/invite');
}

export function watchUser(userId, message) {
  return doPatchUpdate('/users/' + userId + '/watch', { message });
}

export function unwatchUser(userId) {
  return axios.patch('/users/' + userId + '/unwatch');
}

export function startMeeting(roomId) {
  return axios.patch('/rooms/' + roomId + '/start_meeting');
}

export function endMeeting(roomId) {
  return axios.patch('/rooms/' + roomId + '/end_meeting');
}

export function updateStatus(status, userId) {
  return doPatchUpdate('/users/' + userId, { status });
}

export function updateState(state, userId) {
  return doPatchUpdate('/users/' + userId, { state });
}

export function updateEmotion(emotion, userId) {
  doPatchUpdate('/users/' + userId, { emotion });
}

export function updateColor(color, userId) {
  doPatchUpdate('/users/' + userId, { color });
}

export function updateBackBy(backByTime, userId) {
  return doPatchUpdate('/users/' + userId, { back_by: backByTime });
}

export function setUserAvailable(userId) {
  doPatchUpdate('/users/' + userId, { back_by: null, state: STATES.AVAILABLE, status: '' });
}

export function setUserSocial(userId) {
  doPatchUpdate('/users/' + userId, { back_by: null, state: STATES.SOCIAL, status: '' });
}

export function enterRoom(roomId) {
  return axios.patch('/rooms/' + roomId + '/enter');
}

export function knock(roomId) {
  return axios.post('/rooms/' + roomId + '/knock');
}

export function claimRoom(roomId) {
  return axios.patch('/rooms/' + roomId + '/claim');
}

export function assignRoomToUser(roomId, userId) {
  return axios({
    method: 'patch',
    url: '/rooms/' + roomId + '/claim',
    data: { user_id: userId },
  });
}

export function sendUserHome(userId) {
  return axios.patch('/users/' + userId + '/send_home');
}

export function fetchHistory() {
  return axios.get('/history');
}

export function signOut() {
  window.logout = true;
  return axios.get('/users/sign_out');
}

export function logOut(userId) {
  return doPatchUpdate('/users/' + userId + '/logout');
}

export function setTheme(theme, userId) {
  return doPatchUpdate('/users/' + userId, { theme })
    .then(() => {
      location.reload(true);
    });
}

export function setTimezone(timezone, userId) {
  return doPatchUpdate('/users/' + userId, { timezone });
}

export function updatePMI(userId, usePmi) {
  doPatchUpdate('/users/' + userId, { 'use_pmi': usePmi });
}

export function updateNativeWatching(userId, useNativeWatching) {
  doPatchUpdate('/users/' + userId, { 'use_native_watching': useNativeWatching });
}

export function updateTourTaken(userId) {
  return doPatchUpdate('/users/' + userId, { tour_taken: true });
}

export function updateUseExternalWindow(userId, useExternalWindow) {
  doPatchUpdate('/users/' + userId, { 'open_zoom_silently': useExternalWindow });
}

export function updateHighscore(userId, score) {
  doPatchUpdate('/users/' + userId, { score });
}

export function fetchHighscore() {
  return axios.get('/high_score.json');
}

export function renameRoom(roomId, name) {
  return doPatchUpdate('/rooms/' + roomId, { name: name });
}

export function checkForSlack(userId) {
  return axios.get('/users/' + userId + '/slack_presence');
}

export function fetchSessions(id) {
  return axios.get('/conferences/' + id + '/sessions.json');
}

export function addSession(session, conferenceId) {
  if (session.id) {
    return doPatchUpdate('/conferences/' + conferenceId + '/sessions/' + session.id, _.omit(session, 'id'));
  }
  return axios({
    method: 'post',
    url: '/conferences/' + conferenceId + '/sessions',
    data: session,
  });
}

export function addOrUpdatePartnerPass(partner, partyId) {
  if (partner.id) {
    return doPatchUpdate('/partner_parties/' + partyId + '/partner_passes/' + partner.id+'.json', _.omit(partner, ['id', 'partner_party_id']));
  }
  return axios({
    method: 'post',
    url: '/partner_parties/' + partyId + '/partner_passes.json',
    data: _.omit(partner, ['id', 'partner_party_id']),
  });
}
export function deletePartnerPass(partnerId, partyId) {
  return axios({
    method: 'delete',
    url: '/partner_parties/' + partyId + '/partner_passes/'+ partnerId,
  });
}
export function fetchPartnerPasses(partyId) {
    return axios.get('/partner_parties/'+ partyId + '/partner_passes/');
}
export function addConference(conference) {
  if (conference.id) {
    return doPatchUpdate('/conferences/' + conference.id, _.omit(conference, 'id'));
  }

  return axios({
    method: 'post',
    url: '/conferences',
    data: conference,
  });
}

export function addPartnerFloor(party) {
  if (party.id) {
    return doPatchUpdate('/partner_parties/' + party.id, _.omit(party, 'id'));
  }

  return axios({
    method: 'post',
    url: '/partner_parties',
    data: party,
  });
}


export function registerConferenceGuest(guest) {
  return axios({
    method: 'post',
    url: '/conference_guests.json',
    data: { conference_guest: guest },
  })
}

export function registerPartner(partner) {
  return axios({
    method: 'post',
    url: '/partners.json',
    data: { partner },
  })
}


export function signInConferenceGuest(conferenceUser) {
  return axios({
    method: 'post',
    url: '/conference_guests/sign_in.json',
    data: { conference_guest: conferenceUser },
  });
}

export function fetchWeather() {
  return axios.get('/weather');
}

export function callUser(userId) {
  doPatchUpdate('/users/' + userId + '/call', {});
}

export function inviteAll(roomId) {
  return axios.post('/rooms/' + roomId + '/invite');
}

export function inviteByDepartment(departmentId, roomId) {
  return axios({
    method: 'post',
    url: '/departments/' + departmentId + '/invite',
    data: { room_id: roomId },
  });
}

export function renameUser(userId, name) {
  return doPatchUpdate('/users/' + userId, { first_name: name });
}

export function setUserToLoginWithEmail(userId, value) {
  return doPatchUpdate('/users/' + userId, { backup_login: value });
}

export function updateDepartmentAssignment(userId, departmentId) {
  return doPatchUpdate('/users/' + userId, { department_id: departmentId });
}

export function updateFirstName(userId, name) {
  return doPatchUpdate('/users/' + userId, { first_name: name });
}

export function updateLastName(userId, name) {
  return doPatchUpdate('/users/' + userId, { last_name: name });
}

export function renameFloor(floorId, name) {
  return doPatchUpdate('/floors/' + floorId, { name });
}

export function updateFloor(floorId, floor) {
  return doPatchUpdate('/floors/' + floorId, floor);
}

export function setUserPresent(userId) {
  return doPatchUpdate('/users/' + userId, { present: true });
}

export function setUserOffline(userId) {
  return doPatchUpdate('/users/' + userId, { present: false });
}

export function ping(userId) {
  return doPatchUpdate('/users/' + userId + '/ping');
}

export function starRoom(roomId, position) {
  return doPatchUpdate('/rooms/' + roomId + '/star', { position_id: position });
}

export function unstarRoom(roomId) {
  return doPatchUpdate('/rooms/' + roomId + '/unstar');
}

export function pinDepartment(departmentId) {
  return doPatchUpdate('/departments/' + departmentId + '/pin');
}

export function unpinDepartment(departmentId) {
  return doPatchUpdate('/departments/' + departmentId + '/unpin');
}

export function deleteUser(userId) {
  return axios({
    method: 'delete',
    url: '/users/' + userId,
  });
}

export function logoutUser(userId) {
  return doPatchUpdate('/users/' + userId + '/logout')
    .then(() => showSuccessNotification('User logged out.'));
}

export function deleteSession(sessionId, conferenceId) {
  return axios({
    method: 'delete',
    url: '/conferences/' + conferenceId + '/sessions/' + sessionId,
  });
}

export function deleteConference(conferenceId) {
  return axios({
    method: 'delete',
    url: '/conferences/' + conferenceId,
  });
}

export function deletePartnerFloor(id) {
  return axios({
    method: 'delete',
    url: '/partner_parties/' + id,
  });
}

export function deleteFloor(floorId) {
  return axios({
    method: 'delete',
    url: '/floors/' + floorId,
  });
}

export function deleteDepartment(departmentId) {
  return axios({
    method: 'delete',
    url: '/departments/' + departmentId,
  });
}

export function loadApplicationSettings() {
  return axios.get('/settings.json');
}

export function logoutEveryone() {
  return doPatchUpdate('/settings/master_logout', {});
}

export function refreshEveryone() {
  return doPatchUpdate('/settings/master_refresh', {});
}

export function setAdminOnlyModeAPI(adminOnly) {
  return doPatchUpdate('/settings', { admin_mode: adminOnly });
}

export function setSelfRegistrationAPI(selfRegistration) {
  return doPatchUpdate('/settings', { self_registration: selfRegistration });
}

export function setOTPAPI(otp) {
  return doPatchUpdate('/settings', { otp });
}

export function setShowElevatorLetters(showLetters) {
  return doPatchUpdate('/settings', { number_elevator: !showLetters });
}

export function makeUserAdmin(userId, admin) {
  return doPatchUpdate('/users/' + userId, { admin });
}

export function makeUserEventAdmin(userId, conference_admin) {
  return doPatchUpdate('/users/' + userId, { conference_admin });
}

export function renameDepartment(departmentId, name, parent_id) {
  return doPatchUpdate('/departments/' + departmentId, { name, parent_id });
}

export function updateFloorSize(floorId, size) {
  return doPatchUpdate('/floors/' + floorId, { size });
}

export function addDepartment(name, parent_id) {
  return axios({
    method: 'post',
    url: '/departments',
    data: { name, parent_id },
  });
}

export function fetchConferenceGuests(conferenceId) {
  return axios.get('/conferences/' + conferenceId + '/emails');
}

export function fetchPartnerFloors() {
  return axios.get('/partner_parties');
}

export function addFloor(name, size, level) {
  return axios({
    method: 'post',
    url: '/floors',
    data: { name, size, level },
  });
}

export function loadOfficeConfig() {
  return axios.get('/office.json');
}

export function addUser(userObj) {
  return axios({
    method: 'post',
    url: '/users/welcome',
    data: userObj,
  });
}

export function reorderFloors(sortedFloorIds) {
  return axios({
    method: 'patch',
    url: '/floors/reorder',
    data: { order: sortedFloorIds },
  });
}

export function fetchSlackChannel() {
  return axios.get('/slack/channels.json');
}

export function createWorkspace(email, lite = false) {
  return axios({
    method: 'post',
    url: '/workspace_definitions',
    data: { email, lite },
  });
}

export function fetchWorkspace({ token, email }) {
  return axios.get('/workspace_definitions', { params: { token, email } });
}

export function destroyWorkspace(workspaceId) {
  return axios({
    method: 'delete',
    url: '/workspaces/' + workspaceId,
  });
}

export function getNothing() {
  return axios.get('/nothing');
}

export function finalizeWorkspace({ stripeToken, workspaceToken, email, subdomain, size }) {
  return doPatchUpdate('/workspace_definitions', {
    stripe_token: stripeToken,
    subdomain,
    size,
    token: workspaceToken,
    email,
  });
}

export function generateWorkspace({ email, token }) {
  return axios({
    method: 'post',
    url: '/workspace_definitions/generate',
    data: { email, token },
  });
}

export function doPatchUpdate(endpoint, data) {
  if (endpoint) {
    return axios({
      method: 'patch',
      url: endpoint,
      data: data,
    });
  } else {
    bugsnag.notify('Patch without an endpoint');
    return Promise.resolve();
  }
}

export function testNotification() {
  return axios.get('/test/notify')
}

export function testNotificationAction() {
  return axios.get('/test/notify_action'); // TODO: make sure if request ID is null just accept it and move on. test
                                           // that!
}

export function testSlack() {
  return axios.get('/test/slack')
}

export function testCable() {
  return axios.get('/test/cable')
}

export function acceptKnock(user_id, room_id) {
  return axios({
    method: 'post',
    url: 'room_requests/accept?user_id=' + user_id + '&room_id=' + room_id,
    data: {},
  });
}

export function setWaitingRoomEnabledFlag(enabled) {
  return doPatchUpdate('/settings', { waiting_room: enabled });
}

export function unclaimRoom(roomId) {
  return doPatchUpdate('/rooms/' + roomId + '/unclaim');
}

export function verifySubdomain(subdomain) {
  return axios.get('/workspace_definitions/exists', { params: { subdomain } })
}

export function inviteToQube(email) {
  return axios({
    method: 'post',
    url: '/invites/welcome',
    data: { email },
  });
}
export function addFloatingUser(email) {
  return axios({
    method: 'post',
    url: '/floating_zoom_accounts',
    data: { email },
  });
}

export function deleteFloatingUser(id) {
  return axios({
    method: 'delete',
    url: '/floating_zoom_accounts/'+id,
  });
}

export function getFloatingUsers() {
  return axios.get('/floating_zoom_accounts');
}

export function newsletterSubscription(email) {
  return axios({
    method: 'post',
    url: '/newsletters/subscribe',
    data: { email },
  });
}

export function registerQubeLiteUser(subdomain, email, password, firstName, lastName, browserTimezone) {
  return axios({
    method: 'post',
    url: '/users.json',
    data: {
      subdomain,
      user: {
        email,
        password,
        password_confirmation: password,
        first_name: firstName,
        last_name: lastName,
        timezone: browserTimezone,
      },
    },
  });
}

export function loginWithDevise(email, password) {
  return axios({
    method: 'post',
    url: '/users/sign_in.json',
    data: {
      user: {
        email,
        password,
      },
    },
  });
}


export function loginWithOTP(email, password) {
  return axios({
    method: 'post',
    url: '/users/otp',
    data: {
      user: {
        email,
        password,
      },
    },
  });
}


export function resetMyPassword(email) {
  return axios({
    method: 'post',
    url: '/users/password.json',
    data: {
      user: {
        email,
      },
    },
  });
}

export function changeMyPassword(password, pwdConfirm, resetToken) {
  return doPatchUpdate('/users/password.json',
    { user: { password, password_confirmation: pwdConfirm, reset_password_token: resetToken } });
}

export function pinUser(pinnedUserId) {
  return doPatchUpdate('/users/' + pinnedUserId + '/pin');
}

export function pinConferenceRoom(pinnedRoomId) {
  return doPatchUpdate('/rooms/' + pinnedRoomId + '/pin');
}

export function unpinConferenceRoom(pinnedRoomId) {
  return doPatchUpdate('/rooms/' + pinnedRoomId + '/unpin');
}

export function unpinUser(pinnedUserId) {
  return doPatchUpdate('/users/' + pinnedUserId + '/unpin');
}

export function sendSignupMessage({ first_name, last_name, role, message, email, slack, zoom }) {
  return axios({
    method: 'post',
    url: '/workspaces/interest',
    data: { first_name, last_name, role, message, email, slack, zoom },
  });
}

export function setRoomTopic(roomId, topic) {
  return doPatchUpdate('/rooms/' + roomId, { topic });
}

export function setPersistentMtgUrl(roomId, persistentMeetingURL) {
  return doPatchUpdate('/rooms/' + roomId, { persistent_meeting_url: persistentMeetingURL });
}


export function fetchMobileSetupData() {
  return axios.get('/preview')
}

export function getRecentLoggedInPartners() {
  return axios.get('users/partners');
}
export function fetchUser(userId) {
  return axios.get('users/'+userId+'.json');
}