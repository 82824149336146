export const STATES = {
    AVAILABLE: 'available',
    SOCIAL: 'feeling social',
    RIGHT_BACK: 'BRB',
    FOCUS_MODE: 'focused',
    BUSY: 'busy',
    AWAY: 'away'
};

export const EVENT_STATE = {
    NOW: 'Now',
    UPCOMING: 'Coming up',
    PAST: 'Past',
    SUPPORT: 'Support',
    STARTING_SOON: 'Starting soon'
};

export const AVATAR_DISPLAY_LOCATIONS = {
    HEADER: 'Header',
    SIDE_PANEL: 'SidePanel',
    ROOM: 'Room',
    ROOM_CONDENSED: 'RoomCondensed'
};

export const EMOTIONS = [
    'flaticon-happy-4',
    'flaticon-happy',
    'flaticon-happy-1',
    'flaticon-happy-2',
    'flaticon-happy-5',
    'flaticon-happy-6',
    'flaticon-giggle',
    'flaticon-pirate',
    'flaticon-pirate-1',
    'flaticon-pirate-2',
    'flaticon-pirate-3',
    'flaticon-pirate-4',
    'flaticon-looking-1',
    'flaticon-nerd',
    'flaticon-happy',
    'flaticon-looking-2',
    'flaticon-sad-2',
    'flaticon-shocked',
    'flaticon-shocked-2',
    'flaticon-shocked-1',
    'flaticon-shocked-3',
    'flaticon-bored',
    'flaticon-bored-1',
    'flaticon-crying',
    'flaticon-dead',
    'flaticon-goofy-3',
    'flaticon-wink',
    'flaticon-sad',
    'flaticon-sad-1',
    'NONE'
];

export const COLORS = [
    '#4055A3', '#068C45', '#9D248F', '#CE561B', '#00A2DD', '#E1498E', '#996442', '#4B7ABD', '#AC4625', '#D3AA4C',
    '#6263AD', '#F47B37', '#AE6A8B', '#36937E', '#2DBBA7', '#F27295', '#767538', '#542B7C', '#AE752A', '#59BC73',
    '#67B148', '#A1849B', '#115B6B', '#46733C', '#992542', '#B0D035', '#78955F', '#BEB12B', '#7AC0B5', '#7197B7'
];

export const FLOOR_SIZES = [10,  25, 35, 45, 54, 66];

export const WEATHER_THEME = 'weather';

export const SIDE_PANEL_DISPLAY = {
    DEFAULT: 'Default',
    BY_ROOM: 'by Room',
    BY_DEPARTMENT: 'by Dept',
    BY_FLOOR: 'by Floor'
};

export const PIN_ALLOWANCE_CUT_OFF = 25;
